import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import {
  InvestorGoalScheduledDistributionWealthFlexibleDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/domain/dto/investor-goal-scheduled-distribution-wealth-flexible-dto';
import { WealthFlexibleDepositStateManager } from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type LinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  alt: string;
  assigned_amount_field: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class AllianzDepositFlexibleGoalsDistributionViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

 @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible-deposit-goals-distribution';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  linked_goals: Array<LinkedGoals> = [];

  step = 50;

  is_disabled = true;

  is_checkbox_active = false;

  index_unassigned_amount = 0;

  deposit_amount = 0;

  new_deposit_amount = '0';

  is_any_goal_active = false;

  payload_to_save_distribution: InvestorGoalScheduledDistributionWealthFlexibleDto = {
    total_contribution: 0,
    unassigned_balance: 0,
    is_deposit: true,
    investor_goal_scheduled_distributions: [],
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  input_rules = {
    amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
    ],
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  initialize = async () => {
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setUnassignedAmount();
    this.initializeAmount();
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '30px' : '21px');

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary rounded-t-lg' : '');

  getCustomizedModerateBackgroundColorForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');

  getCustomizedModerateClassForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'white-font' : '');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      this.active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      let is_moderate = false;
      this.active_goals.forEach((goal: any) => {
        this.is_any_goal_active = true;
        is_moderate = false;
        if (goal.investment_product_fund_types && goal.investment_product_fund_types.linked) {
          is_moderate = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        this.linked_goals.push({
          investor_goal_id: goal.investor_goal_id,
          // eslint-disable-next-line max-len
          name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.name,
          // eslint-disable-next-line max-len
          label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          assigned_amount: 0,
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          assigned_amount_field: '0',
          is_moderate,
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0',
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
  }

  updateAmount = () => {
    this.wealth_flexible_deposit_state_manager.state.deposit.amount = this.new_deposit_amount;
    this.setDepositAmount();
  }

  setDepositAmount = () => {
    const { amount } = this.wealth_flexible_deposit_state_manager.state.deposit;
    if (amount !== '') {
      this.payload_to_save_distribution.investor_goal_scheduled_distributions.length = 0;
      const parsed_number_without_characters = parseFloat(amount.replaceAll(/[^\d.]/g, ''));
      const index = this.linked_goals.findIndex((item) => item.name === 'unassigned');
      this.linked_goals.forEach((item, position) => {
        if (position !== index) {
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount = 0;
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount_field = '0';
        }
      });
      this.is_checkbox_active = false;
      this.deposit_amount = parsed_number_without_characters;
      this.new_deposit_amount = parsed_number_without_characters.toString();
      this.linked_goals[index].assigned_amount = parsed_number_without_characters;
      this.index_unassigned_amount = index;
    }
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(false, index);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(true, index);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= 0) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount = res;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  // eslint-disable-next-line max-len
  validateTotalAmounts = (is_add: boolean, position_array_linked_goal: number, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    this.is_disabled = total_amount_goals !== this.deposit_amount;
  }

  distributeDepositAmongGoals = () => {
    if (this.is_checkbox_active) {
      let total_distribute_amount = 0;
      const size_linked_goals = this.linked_goals.length - 1;
      const amount_equally = this.deposit_amount / size_linked_goals;
      this.linked_goals.forEach((item, index) => {
        if (item.investor_goal_id !== '') {
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount = Math.floor(amount_equally);
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount_field = String(item.assigned_amount);
          total_distribute_amount += item.assigned_amount;
        }
      });
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_distribute_amount;
      this.is_disabled = false;
    }
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = String(parsed_assigned_amount_field);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, index, true);
  }

  nextStep = () => {
    let total_contribution = 0;
    this.payload_to_save_distribution.investor_goal_scheduled_distributions = [];
    this.linked_goals.forEach((goal) => {
      if (goal.investor_goal_id && goal.assigned_amount > 0) {
        this.payload_to_save_distribution.investor_goal_scheduled_distributions.push(
          {
            id: v4(),
            investor_goal_id: goal.investor_goal_id,
            // TODO el id de abajo se obtendra de un endpoint, todavia no existe ese endpoint
            investment_product_fund_type_id: '81ce09c5-488d-4821-aa5e-c9ffefd052e1',
            amount_to_distribute: goal.assigned_amount,
          },
        );
      }
      total_contribution += goal.assigned_amount;
    });
    this.payload_to_save_distribution.unassigned_balance = this
      .linked_goals[this.index_unassigned_amount].assigned_amount;
    this.payload_to_save_distribution.total_contribution = total_contribution;
    this.wealth_flexible_deposit_state_manager.patch(
      { goal_distribution: this.payload_to_save_distribution },
    );
    this.view.$emit('nextStep');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  initializeAmount = () => {
    this.new_deposit_amount = this.wealth_flexible_deposit_state_manager.state.deposit.amount;
    this.setDepositAmount();
  }
}
