














































































































































































import {
  Component, Vue, Watch, PropSync,
} from 'vue-property-decorator';

import AllianzDepositFlexibleGoalsDistributionViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/flexible_deposits/allianz-deposit-flexible-goals-distribution-view-model';

@Component({
  name: 'AllianzDepositFlexibleGoalsDistribution',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class AllianzDepositFlexibleGoalsDistribution extends Vue {
  @PropSync('specialDeposit', { type: Boolean, required: false, default: false })
  special_deposit!: boolean;

  allianz_deposit_flexible_goals_distribution_view_model = Vue.observable(
    new AllianzDepositFlexibleGoalsDistributionViewModel(this),
  );

  @Watch('allianz_deposit_flexible_goals_distribution_view_model.wealth_flexible_deposit_state_manager.state.deposit.amount')
  onAmountChange() {
    this.allianz_deposit_flexible_goals_distribution_view_model.setDepositAmount();
  }

  created() {
    this.allianz_deposit_flexible_goals_distribution_view_model.initialize();
  }
}
